<template>
    <div class="MaterialInd">
        <p class="p justify">
            我们可以针对具体的生产环节分析要求，对材料产业中出现的各种材料物性提供计算模拟服务，帮助客户了解生产过程，优化生产参数，搜寻更高效廉价的新材料。
        </p>
        <p class="p justify">
            随着半导体技术的深化发展，半导体器件从底层材料到器件功能结构都需要仿真模拟模块的介入。我们可以分析技术症结、辅助以现有的模拟工具、再合理结合部分自己原生开发的功能模块或者接口模块，从产业服务的角度进行多尺度全方位的模拟，帮助用户解决实际的生产设计难题。
        </p>
        <el-tag
            effect="dark"
        >
            案例：磁栅尺材料仿真
        </el-tag>
        <p class="p justify">
            聚焦关键磁性材料仿真，同国内某知名电梯智能制造企业合作进行电梯磁栅尺定位系统原型机和小规模产业化项目。
        </p>
        <b-row align-h="center">
            <b-col
                lg="9"
                cols="12"
            >
                <img
                    class="img-info"
                    src="@/assets/images/materia/ind/cizhachi.png"
                >
            </b-col>
        </b-row>

        <el-tag
            effect="dark"
        >
            案例：SiC/SiO2  MOSFET性能模拟
        </el-tag>
        <p class="p justify">
            优化第三代半导体器件生产工艺，同国内某知名半导体公司合作模拟SiC/SiO2界面对器件性能的影响。
        </p>
        <b-row align-h="center">
            <b-col
                lg="9"
                cols="12"
            >
                <img
                    class="img-info"
                    src="@/assets/images/materia/ind/MOSFET.png"
                >
            </b-col>
        </b-row>
        <el-tag
            effect="dark"
        >
            其他方向
        </el-tag>
        <ul class="MaterialInd-ul">
            <li>第三代半导体生长MOCVD工艺优化</li>
            <li>半导体元器件界面结构控制</li>
            <li>杂质对SiC器件迁移率的影响</li>
            <li>GaN生产过程中和衬底应力适配</li>
            <li>ALD沉积过程中气流的控制</li>
            <li>锂离子电池寿命和材料的关系</li>
            <li>磁性材料退火过程的仿真模拟</li>
            <li>药物中有效分子提纯的工艺模拟</li>
            <li>多铁材料器件的电学特性模拟</li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'MaterialInd',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '材料设计与器件仿真解决方案 - 生产制造方向')
      }
}
</script>
<style lang="less" scoped>
.MaterialInd{
    margin-bottom:6.25rem;
    /deep/.el-tag--dark{
      margin-top:3.125rem!important;
    }
    .MaterialInd-ul{
      padding-left: 1rem;
    }
    .MaterialInd-ul>li{
      color: rgba(0,0,0,.65);
      font-size: 1.125rem;
    }
}

</style>
